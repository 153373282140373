<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="1000"
      ><template v-slot:activator="{ on }">
        <v-text-field
          v-model="text"
          :label="label"
          readonly
          :dense="dense"
          v-on="on"
          :error-messages="errorMessages"
          ><template slot="append">
            <v-btn icon v-if="clearable && text" @click="clearClick">
              <v-icon>{{ icon.clear }}</v-icon>
            </v-btn>
            <v-icon @click="menu = true">{{ icon.search }}</v-icon>
            <v-icon
              v-if="value && showLink"
              :color="feature.sites.color"
              @click="showSiteEditorClick"
            >
              {{ icon.site }}
            </v-icon>
          </template>
        </v-text-field>
      </template>
      <SiteSearch
        :searchText="text"
        @select="onSelect"
        :activeOnly="activeOnly"
      ></SiteSearch>
    </v-menu>

    <v-dialog
      persistent
      v-model="showSiteEditorDialog"
      :width="feature.sites.dialogWidth"
    >
      <SiteEditor
        :siteId="value"
        :isDialogOpen="showSiteEditorDialog"
        @update="siteEditorCloseEvent"
        @close="siteEditorCloseEvent"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { icon, feature } from '@/use/Constants'
import SiteSearch from '@/components/Site/SiteSearch'
import SiteEditor from '@/components/Site/SiteEditor'

export default {
  name: 'SitePicker',
  components: { SiteSearch, SiteEditor },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    showLink: {
      type: Boolean,
      default: true
    },
    activeOnly: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    const menu = ref(false)
    const showSiteEditorDialog = ref(false)

    const showSiteEditorClick = () => {
      showSiteEditorDialog.value = true
    }

    const siteEditorCloseEvent = () => {
      showSiteEditorDialog.value = false
    }

    const onSelect = event => {
      context.emit('input', event.SiteId)
      context.emit('update:text', event.SiteName)
      menu.value = false
    }

    const clearClick = () => {
      context.emit('input', null)
      context.emit('update:text', null)
    }

    return {
      menu,
      clearClick,
      onSelect,
      showSiteEditorDialog,
      showSiteEditorClick,
      siteEditorCloseEvent,
      feature,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
