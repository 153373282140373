<template>
  <div>
    <v-card max-width="9000">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Sms Template List
        </DialogTitle>
      </v-card-title>
      <v-data-table
        dense
        :items-per-page="10"
        :footer-props="{
          'show-first-last-page': true
        }"
        :headers="headers"
        :items="infoList"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:item.Actions="{ item }">
          <v-icon small class="mr-2" @click="editItemClick(item)">
            {{ icon.lineItemEdit }}
          </v-icon>
          <v-icon small @click="deleteItemClick(item)">
            {{ icon.lineItemDelete }}
          </v-icon>
        </template>
      </v-data-table>
      <div class="table-footer-prepend d-flex pl-2 align-center">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="newItemClick">
              <v-icon>{{ icon.lineItemAdd }}</v-icon>
            </v-btn>
          </template>
          <span>New Sms Template</span>
        </v-tooltip>
      </div>
    </v-card>

    <v-dialog v-model="showSmsTemplateEditorDialog" width="1146" persistent>
      <SmsTemplateEditor
        :SmsTemplateId="selectedSmsTemplateId"
        :isDialogOpen="showSmsTemplateEditorDialog"
        @close="SmsTemplateEditorCloseEvent"
        @update="SmsTemplateEditorUpdateEvent"
      ></SmsTemplateEditor>
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import SmsTemplateEditor from '@/components/Setup/MessagingTemplates/SmsTemplateEditor'

export default {
  name: 'SmsTemplateList',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { SmsTemplateEditor },
  setup(props) {
    const dialogUtil = ref(null)
    const loading = ref(false)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      infoList.value = []
      getSmsTemplateList()
    }

    const infoList = ref([])
    const selectedSmsTemplateId = ref(null)
    const showSmsTemplateEditorDialog = ref(false)

    const headers = [
      { value: 'SmsEvent', text: 'Event', width: 100, align: 'left' },
      {
        value: 'TemplateName',
        text: 'Tempate Name',
        width: 100,
        align: 'left'
      },
      { value: 'MessageBody', text: 'Body', width: 100, align: 'left' },
      { value: 'SortOrder', text: 'Order', width: 40, align: 'right' },
      {
        value: 'Actions',
        text: 'Actions',
        sortable: false,
        width: 60,
        align: 'right'
      }
    ]

    function editItemClick(item) {
      selectedSmsTemplateId.value = item.SmsTemplateId
      showSmsTemplateEditorDialog.value = true
    }

    function deleteItemClick(item) {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete Sms Template: ${item.TemplateName}`
        })
        .then(() => performDeleteSmsTemplate(item.SmsTemplateId))
        .catch(() => {
          return
        })
    }

    function SmsTemplateEditorCloseEvent() {
      showSmsTemplateEditorDialog.value = false
    }

    function SmsTemplateEditorUpdateEvent() {
      showSmsTemplateEditorDialog.value = false
      getSmsTemplateList()
    }

    async function getSmsTemplateList() {
      loading.value = true
      const rq = {}
      const rs = await host.smsTemplate.list(rq)
      infoList.value = rs.InfoList
      loading.value = false
    }

    async function performDeleteSmsTemplate(id) {
      const rq = { InfoId: id }
      const rs = await host.smsTemplate.delete(rq)
      if (rs.IsSuccess) {
        getSmsTemplateList()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    function newItemClick() {
      selectedSmsTemplateId.value = null
      showSmsTemplateEditorDialog.value = true
    }

    initDialog()

    return {
      dialogUtil,
      headers,
      infoList,
      selectedSmsTemplateId,
      editItemClick,
      deleteItemClick,
      newItemClick,
      showSmsTemplateEditorDialog,
      SmsTemplateEditorCloseEvent,
      SmsTemplateEditorUpdateEvent,
      icon,
      loading
    }
  }
}
</script>

<style lang="scss" scoped></style>
