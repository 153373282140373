<template>
  <div>
    <v-card class="mt-2">
      <v-card-title>
        <span class="text-subtitle-1">Services</span>
        <v-spacer />
        <span>
          <div class="d-flex">
            <v-tooltip top v-if="!isAccountNotActive">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="newScheduledServiceClick"
                >
                  <v-icon :color="feature.services.color">
                    {{ feature.services.iconNewService }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                Create new Service
              </span>
            </v-tooltip>
            <v-tooltip top v-if="!isAccountNotActive">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-1"
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="newRecurringServiceClick"
                >
                  <v-icon :color="feature.recurringServices.color">
                    {{ feature.recurringServices.icon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                Create new Recurring Service
              </span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="navigateToServiceSearch"
                  class="mr-1"
                >
                  <v-icon :color="feature.services.color">
                    {{ feature.services.icon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                See Service list
              </span>
            </v-tooltip>
          </div>
        </span>
      </v-card-title>
      <v-card-text>
        <ServiceSummaryCard
          v-for="ss in cardData.ServiceList"
          :key="ss.ScheduledServiceId"
          :ss="ss"
          @editScheduledService="editScheduledServiceEvent"
        />
      </v-card-text>
    </v-card>

    <v-dialog
      persistent
      v-model="showScheduledServiceEditorDialog"
      :width="feature.services.dialogWidth"
    >
      <ScheduledServicesEditor
        :isDialogOpen="showScheduledServiceEditorDialog"
        :siteId="cardData.SiteId"
        :scheduledServiceId="selectedScheduledServiceId"
        @close="scheduledServiceEditorCloseEvent"
        @update="scheduledServiceEditorUpdateEvent"
        @editRecurringService="editRecurringServiceEvent"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="showRecurringServiceEditorDialog"
      :width="feature.recurringServices.dialogWidth"
    >
      <RecurringServiceEditor
        :siteId="cardData.SiteId"
        :recurringServiceId="selectedRecurringServiceId"
        :isDialogOpen="showRecurringServiceEditorDialog"
        @update="recurringServiceEditorUpdateEvent"
        @close="recurringServiceEditorCloseEvent"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { feature } from '@/use/Constants'
import RecurringServiceEditor from '@/components/ScheduledService/RecurringServiceEditor'
import ServiceSummaryCard from '@/components/ScheduledService/ServiceSummaryCard'
import ScheduledServicesEditor from '@/components/ScheduledService/ScheduledServiceEditor'
export default {
  props: {
    cardData: { type: Object, required: true },
    isAccountNotActive: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RecurringServiceEditor,
    ServiceSummaryCard,
    ScheduledServicesEditor
  },
  setup(props, context) {
    const router = useRouter()
    const showScheduledServiceEditorDialog = ref(false)
    const showRecurringServiceEditorDialog = ref(false)
    const selectedScheduledServiceId = ref()
    const selectedRecurringServiceId = ref()

    const navigateToServiceSearch = () => {
      router.push({
        name: feature.services.routerName,
        params: {
          siteId: props.cardData.SiteId,
          siteDescription: props.cardData.Description
        }
      })
    }

    const newScheduledServiceClick = () => {
      selectedScheduledServiceId.value = null
      showScheduledServiceEditorDialog.value = true
    }

    const scheduledServiceEditorCloseEvent = () => {
      showScheduledServiceEditorDialog.value = false
    }

    const scheduledServiceEditorUpdateEvent = () => {
      showScheduledServiceEditorDialog.value = false
      context.emit('update')
    }

    const newRecurringServiceClick = () => {
      showRecurringServiceEditorDialog.value = true
    }

    const recurringServiceEditorCloseEvent = () => {
      showRecurringServiceEditorDialog.value = false
    }

    const recurringServiceEditorUpdateEvent = () => {
      showRecurringServiceEditorDialog.value = false
      context.emit('update')
    }

    const editScheduledServiceEvent = id => {
      selectedScheduledServiceId.value = id
      showScheduledServiceEditorDialog.value = true
    }

    const editRecurringServiceEvent = id => {
      selectedRecurringServiceId.value = id
      showRecurringServiceEditorDialog.value = true
    }

    return {
      feature,
      navigateToServiceSearch,
      selectedScheduledServiceId,
      newScheduledServiceClick,
      showScheduledServiceEditorDialog,
      scheduledServiceEditorCloseEvent,
      scheduledServiceEditorUpdateEvent,
      selectedRecurringServiceId,
      newRecurringServiceClick,
      showRecurringServiceEditorDialog,
      recurringServiceEditorCloseEvent,
      recurringServiceEditorUpdateEvent,
      editScheduledServiceEvent,
      editRecurringServiceEvent
    }
  }
}
</script>

<style lang="scss" scoped></style>
