<template>
  <div>
    <div v-if="formData">
      <v-card height="480">
        <v-card-title>
          <DialogTitle :dialogName="$options.name">
            {{ isEditMode ? 'Edit' : 'New' }} Sms Template
          </DialogTitle>
        </v-card-title>
        <v-card-text>
          <div class="d-flex">
            <div>
              <v-card width="300" height="330">
                <v-card-text>
                  <v-select
                    v-if="selectLists"
                    v-model="formData.SmsEventCode"
                    label="Sms Event"
                    :items="selectLists.SmsEvents"
                    item-text="Text"
                    item-value="Value"
                    :error-messages="errorMessages(vuelidate.formData.SmsEvent)"
                    @blur="vuelidate.formData.SmsEvent.$touch()"
                    dense
                  />
                  <v-text-field
                    v-model="formData.TemplateName"
                    label="Template Name"
                    :error-messages="
                      errorMessages(vuelidate.formData.TemplateName)
                    "
                    @blur="vuelidate.formData.TemplateName.$touch()"
                    dense
                  />
                  <v-text-field
                    v-model="formData.SortOrder"
                    label="Sort Order"
                    type="number"
                    dense
                  />
                  <v-checkbox
                    v-model="formData.DoNotSend"
                    label="Do Not Send"
                    dense
                  />
                </v-card-text>
              </v-card>
            </div>
            <div>
              <v-card width="300" height="330" class="ml-4">
                <v-card-title>
                  <div class="text-caption">Substitutions</div>
                </v-card-title>
                <v-card-text>
                  <div style="height: 260px; overflow-y: scroll;">
                    <div v-for="(item, index) in substitutionList" :key="index">
                      <span>{</span>{{ index }}<span>}</span> {{ item }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
            <div>
              <v-card width="400" height="330" class="ml-3">
                <v-card-text>
                  <v-textarea
                    class="mx-2"
                    v-model="formData.MessageBody"
                    label="Message Body"
                    rows="10"
                    :error-messages="
                      errorMessages(vuelidate.formData.MessageBody)
                    "
                    @blur="vuelidate.formData.MessageBody.$touch()"
                    dense
                  />
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="isEditMode"
            color="success"
            class="ml-3"
            @click="updateClick"
          >
            Update
          </v-btn>
          <v-btn v-else color="success" class="ml-3" @click="saveClick">
            Save
          </v-btn>
          <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { icon } from '@/use/Constants'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: 'SmsTemplateEditor',
  props: {
    SmsTemplateId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  setup(props, context) {
    const dialogUtil = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const formData = ref()
    const selectLists = ref()
    const SmsInsertHeaders = [
      { value: 'Name', text: 'Name', width: 40, align: 'left' },
      { value: 'Text', text: 'Text' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const initDialog = () => {
      loadSelectLists()
      if (props.SmsTemplateId) {
        retrieveSmsTemplate(props.SmsTemplateId)
      } else {
        newTemplate()
      }
    }

    const isEditMode = computed(() => {
      return formData.value != null && formData.value.SmsTemplateId != null
    })

    const substitutionList = computed(() => {
      return formData.value != null &&
        formData.value.SmsEvent != null &&
        selectLists.value != null
        ? getsubstitutionListFor(formData.value.SmsEventCode)
        : []
    })

    const getsubstitutionListFor = SmsEvent => {
      const selectItem = selectLists.value.SmsEvents.find(
        item => item.Value == SmsEvent
      )
      return selectItem ? JSON.parse(selectItem.ExtraData) : []
    }

    const retrieveSmsTemplate = async id => {
      const rq = { InfoId: id }
      const rs = await host.smsTemplate.retrieve(rq)
      formData.value = rs.Info
    }

    const newTemplate = async () => {
      const rq = {}
      const rs = await host.smsTemplate.newTemplate(rq)
      formData.value = rs.Info
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const rules = {
      formData: {
        SmsEvent: { required },
        TemplateName: { required },
        MessageBody: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const validate = () => {
      vuelidate.value.$touch()
      return vuelidate.value.$invalid
    }

    const saveClick = async () => {
      if (validate()) {
        return
      }

      const result = await saveNew()
      afterSaveOrUpdate(result)
    }

    const updateClick = async () => {
      if (validate()) {
        return
      }

      const result = await update()
      afterSaveOrUpdate(result)
    }

    const afterSaveOrUpdate = result => {
      if (result.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(result.Message)
      }
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.smsTemplate.create(rq)
      return rs
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.smsTemplate.update(rq)
      return rs
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.SmsEvents]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    initDialog()

    return {
      dialogUtil,
      formData,
      selectLists,
      isEditMode,
      substitutionList,
      saveClick,
      updateClick,
      cancelClick,
      SmsInsertHeaders,
      icon,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
