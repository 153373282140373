<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          small
          v-bind="attrs"
          v-on="on"
          color="primary"
          @click="sendSmsClick"
        >
          <v-icon :color="feature.sendSms.color">
            {{ feature.sendSms.icon }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ hint }}</span>
    </v-tooltip>

    <v-dialog
      v-model="showSendSmsDialog"
      :max-width="feature.sendSms.dialogWidth"
      persistent
    >
      <SendSmsDialog
        :isDialogOpen="showSendSmsDialog"
        :showWaitDialog="showWaitDialog"
        :SmsEventType="emailEventType"
        :subtitle="subtitle"
        @update="sendSmsDialogUpdate"
        @close="sendSmsDialogClose"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { feature } from '@/use/Constants'
import { ref } from 'vue'
import SendSmsDialog from '@/components/Common/SendSmsDialog'
export default {
  name: 'SendSmsButton',
  props: {
    subtitle: {
      type: String,
      default: 'Please wait'
    },
    showWaitDialog: {
      type: Boolean,
      default: false
    },
    emailEventType: {
      type: String,
      default: 'C'
    },
    hint: {
      type: String,
      default: 'Send Sms Text'
    }
  },
  components: { SendSmsDialog },
  setup(props, context) {
    const dialogUtil = ref()
    const showSendSmsDialog = ref(false)

    const sendSmsClick = () => {
      showSendSmsDialog.value = true
    }

    const sendSmsDialogUpdate = event => {
      context.emit('update', event)
      showSendSmsDialog.value = false
    }

    const sendSmsDialogClose = () => {
      showSendSmsDialog.value = false
    }

    return {
      dialogUtil,
      showSendSmsDialog,
      sendSmsClick,
      sendSmsDialogUpdate,
      sendSmsDialogClose,
      feature
    }
  }
}
</script>

<style lang="scss" scoped></style>
