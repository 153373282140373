export default {
  activityLog: 'mdi-folder-information-outline',
  addNew: 'mdi-plus',
  addNewInvoice: 'mdi-file-document',
  billing: 'mdi-card-account-mail-outline',
  calendar: 'mdi-calendar',
  cardExpand: 'mdi-arrow-down-drop-circle-outline',
  cardCollapse: 'mdi-arrow-up-drop-circle-outline',
  checkBoxCheckAll: 'mdi-checkbox-multiple-outline',
  checkBoxUncheckAll: 'mdi-minus-box-multiple-outline',
  clear: 'mdi-close',
  close: 'mdi-close',
  commission: 'mdi-chart-pie',
  commit: 'mdi-cog-outline',
  completed: 'mdi-check',
  customer: 'mdi-account',
  customerEdit: 'mdi-account-edit',
  customerList: 'mdi-account-multiple',
  dashboard: 'mdi-monitor-dashboard',
  date: 'mdi-calendar',
  delete: 'mdi-delete',
  document: 'mdi-file-outline',
  documentEdit: 'mdi-file-document-edit',
  documentUpload: 'mdi-file-upload-outline',
  duration: 'mdi-clock-time-four-outline',
  edit: 'mdi-pencil',
  email: 'mdi-email',
  emailEdit: 'mdi-email-edit',
  estimate: 'mdi-comment-quote',
  exit: 'mdi-exit-run',
  feedback: 'mdi-email-outline',
  invoice: 'mdi-file-document',
  lineItemAdd: 'mdi-plus',
  lineItemIsChecked: 'mdi-check',
  lineItemIsDeleted: 'mdi-check',
  lineItemDelete: 'mdi-delete',
  lineItemEdit: 'mdi-pencil',
  locked: 'mdi-lock-outline',
  login: 'mdi-login',
  map: 'mdi-google-maps',
  mapDirections: 'mdi-directions',
  mapFind: 'mdi-map-marker-question',
  mapOptimize: 'mdi-map-marker-path',
  measure: 'mdi-scale',
  messageAlert: 'mdi-message-alert-outline',
  office: 'mdi-store',
  onlinePayment: 'mdi-credit-card',
  onlinePaymentAuth: 'mdi-alpha-a-box-outline',
  onlinePaymentAuthApply: 'mdi-credit-card-plus',
  onlinePaymentOneTimePost: 'mdi-credit-card-plus',
  onlinePaymentProcessorInfo: 'mdi-credit-card-check',
  onlinePaymentRecurring: 'mdi-replay',
  onlinePaymentRefund: 'mdi-credit-card-off',
  onlinePaymentVoid: 'mdi-credit-card-off',
  pageTitleDelimiter: 'mdi-arrow-right-drop-circle-outline',
  password: 'mdi-lock',
  passwordHide: 'mdi-eye-off',
  passwordShow: 'mdi-eye',
  paste: 'mdi-content-paste',
  payment: 'mdi-cash-multiple',
  photo: 'mdi-camera',
  photoEdit: 'mdi-image-edit',
  preferences: 'mdi-badge-account',
  priorityHigh: 'mdi-speedometer',
  priorityMedium: 'mdi-speedometer-medium',
  priorityLow: 'mdi-speedometer-slow',
  recurringService: 'mdi-replay',
  radioSelected: 'mdi-checkbox-marked-circle-outline',
  radioUnselected: 'mdi-checkbox-blank-circle-outline',
  refresh: 'mdi-replay',
  report: 'mdi-printer',
  reportFolder: 'mdi-folder',
  reportFolderOpen: 'mdi-folder-open',
  reportInfo: 'mdi-printer-eye',
  reportName: 'mdi-file-outline',
  routes: 'mdi-routes',
  save: 'mdi-content-save',
  scheduledService: 'mdi-car-pickup',
  scheduledTask: 'mdi-reminder',
  search: 'mdi-magnify',
  season: 'mdi-partly-cloudy-day',
  serviceable: 'mdi-information-outline',
  setupMenu: 'mdi-dots-vertical',
  setupDefault: 'mdi-tools',
  subscribe: 'mdi-checkbox-marked-circle-outline',
  signout: 'mdi-exit-to-app',
  site: 'mdi-home',
  siteAdd: 'mdi-home-plus',
  siteEdit: 'mdi-home-edit',
  sliderDecrease: 'mdi-plus',
  sliderIncrease: 'mdi-minus',
  sms: 'mdi-message-text-outline',
  sync: 'mdi-sync',
  task: 'mdi-reminder',
  time: 'mdi-calendar-clock',
  timePicker: 'mdi-clock-time-four-outline',
  timePeriodNext: 'mdi-chevron-right',
  timePeriodPrevious: 'mdi-chevron-left',
  treatment: 'mdi-hammer-wrench',
  tutorial: 'mdi-video',
  tutorialUpload: 'mdi-video-plus',
  unlocked: 'mdi-lock-open-variant-outline',
  user: 'mdi-face-man',
  userGroup: 'mdi-tag-faces',

  serviceTypeChoices: [
    'mdi-account-hard-hat',
    'mdi-account-supervisor',
    'mdi-air-filter',
    'mdi-backup-restore',
    'mdi-broom',
    'mdi-bus-clock',
    'mdi-car-pickup',
    'mdi-calendar-check',
    'mdi-clipboard-check',
    'mdi-close-circle-outline',
    'mdi-hot-tub',
    'mdi-fountain',
    'mdi-phone',
    'mdi-pool',
    'mdi-shield-home-outline',
    'mdi-test-tube',
    'mdi-tools',
    'mdi-water-check-outline'
  ],

  seasonChoices: [
    'mdi-weather-sunny',
    'mdi-weather-cloudy',
    'mdi-weather-cloudy-clock',
    'mdi-weather-partly-snowy-rainy',
    'mdi-weather-pouring',
    'mdi-weather-rainy',
    'mdi-weather-snowy',
    'mdi-weather-sunny-off'
  ]
}
