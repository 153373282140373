<template>
  <div>
    <v-card width="400px" :class="`pt-2 pl-3 ma-2 ${cardColor}`">
      <v-card-title>
        <span
          >Site: {{ cardData.Description }}
          {{ cardData.Inactive ? ' (Inactive)' : '' }}
        </span>
        <v-spacer />
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="editSiteClick">
              <v-icon :color="feature.sites.color" v-bind="attrs" v-on="on">
                {{ feature.sites.iconEdit }}
              </v-icon>
            </v-btn>
          </template>
          <span>Edit Site</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <AddressCard
          :name="cardData.Address.Name"
          :street="cardData.Address.Street"
          :city="cardData.Address.City"
          :state="cardData.Address.State"
          :postCode="cardData.Address.PostCode"
        />
        <div>
          <div v-if="cardData.Supervisor" class="my-2 left">
            Supervisor:
            {{ cardData.Supervisor }}
          </div>
        </div>
        <ServicesCard
          :cardData="cardData"
          :isAccountNotActive="isAccountNotActive"
          @update="servicesCardUpdateEvent"
        />
        <ServiceablesCard
          v-if="cardData.ServiceableTypeList.length > 0"
          :cardData="cardData"
        />
      </v-card-text>
    </v-card>

    <v-dialog v-model="showSiteEditorDialog" :width="feature.sites.dialogWidth">
      <SiteEditor
        :isDialogOpen="showSiteEditorDialog"
        :customerId="cardData.CustomerId"
        :siteId="cardData.SiteId"
        :isDeleteEnabled="isDeleteEnabled"
        @close="siteEditorCloseEvent"
        @update="siteEditorUpdateEvent"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { feature } from '@/use/Constants'
import AddressCard from '@/components/Common/AddressCard'
import ServicesCard from '@/components/ScheduledService/ServicesCard'
import ServiceablesCard from '@/components/Site/ServiceablesCard'
import SiteEditor from '@/components/Site/SiteEditor'
export default {
  name: 'SiteCard',
  props: {
    cardData: {
      type: Object,
      required: true
    },
    isDeleteEnabled: {
      type: Boolean,
      default: false
    },
    isAccountNotActive: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AddressCard,
    SiteEditor,
    ServicesCard,
    ServiceablesCard
  },
  setup(props, context) {
    const showSiteEditorDialog = ref(false)

    const editSiteClick = () => {
      showSiteEditorDialog.value = true
    }

    const siteEditorCloseEvent = () => {
      showSiteEditorDialog.value = false
    }

    const siteEditorUpdateEvent = () => {
      showSiteEditorDialog.value = false
      context.emit('update')
    }

    const servicesCardUpdateEvent = () => {
      context.emit('update')
    }

    const cardColor = computed(() => {
      return props.cardData.Inactive ? 'red lighten-4' : 'light-green lighten-4'
    })

    return {
      feature,
      editSiteClick,
      showSiteEditorDialog,
      siteEditorCloseEvent,
      siteEditorUpdateEvent,
      servicesCardUpdateEvent,
      cardColor
    }
  }
}
</script>

<style lang="scss" scoped>
.left {
  float: left;
  width: 200px;
}
.right {
  float: right;
}
</style>
