<template>
  <div>
    <v-card :width="feature.sendSms.dialogWidth">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Send Sms Text -- {{ subtitle }}
        </DialogTitle>
      </v-card-title>
      <v-card-text v-if="selectLists">
        <div class="d-flex">
          <div>
            <v-card width="350">
              <v-card-text>
                <v-radio-group v-model="eventType" dense class="my-2" row>
                  <v-radio label="General" value="G" />
                  <v-radio label="Promo" value="P" />
                </v-radio-group>
                <v-select
                  v-model="smsTemplateId"
                  :items="applicableTemplateList"
                  item-text="Text"
                  item-value="Value"
                  label="Template"
                  clearable
                  dense
                />
              </v-card-text>
            </v-card>
          </div>
          <div>
            <v-card width="300" class="ml-4">
              <v-card-title>
                <div class="text-caption">
                  Substitutions
                </div>
              </v-card-title>
              <v-card-text>
                <div v-for="(item, index) in substitutionList" :key="index">
                  <span>{</span>{{ index }}<span>}</span> {{ item }}
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
        <v-card class="mt-3" height="290">
          <div class="red--text">
            {{ vuelidate.body.$errors.map(e => e.$message).join(', ') }}
          </div>
          <v-textarea
            class="mx-2 mt-5"
            v-model="body"
            label="Message Body"
            rows="10"
            :error-messages="errorMessages(vuelidate.body)"
            @blur="vuelidate.body.$touch()"
            dense
          />
        </v-card>
      </v-card-text>
      <v-card class="mt-2">
        <v-card-actions>
          <v-btn color="success" class="ml-2" @click="sendTextClick">
            Send Text
          </v-btn>
          <v-spacer />
          <v-btn color="error" class="mr-2" @click="cancelClick">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>

    <WaitDialog v-model="showWaitDialog" :title="title" :subtitle="subtitle" />
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { feature } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import WaitDialog from '@/components/Common/WaitDialog'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: 'SendSmsDialog',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Sending Text'
    },
    subtitle: {
      type: String,
      default: 'Please wait'
    },
    showWaitDialog: {
      type: Boolean,
      default: false
    }
  },
  components: { WaitDialog },
  setup(props, context) {
    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const eventType = ref('G')
    const smsTemplateId = ref(null)
    const body = ref('')
    const selectLists = ref()
    const substitutionList = ref()

    const applicableTemplateList = computed(() =>
      eventType.value == 'G'
        ? selectLists.value.SmsTemplatesGeneral
        : selectLists.value.SmsTemplatesPromo
    )

    const initDialog = async () => {
      smsTemplateId.value = null
      body.value = ''
      await loadSelectLists()
      substitutionList.value = getSubstitutionListFor(eventType.value)
      vuelidate.value.$reset()
    }

    const getSubstitutionListFor = eventType => {
      const extraData = selectListCache.attribute(
        selectListCache.name.SmsEvents,
        eventType,
        'ExtraData'
      )

      console.log({
        loc: 'SendSmsDialog/getSubstitutionListFor',
        eventType: eventType,
        list: selectLists.value.SmsEvents,
        extraData: extraData
      })

      return extraData ? JSON.parse(extraData) : []
    }

    watch(
      () => eventType.value,
      newVal => {
        body.value = null
        getSubstitutionListFor(newVal)
      }
    )

    watch(
      () => smsTemplateId.value,
      newVal => {
        loadSmsTemplateInfo(newVal)
      }
    )

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.SmsEvents,
        selectListCache.name.SmsTemplatesGeneral,
        selectListCache.name.SmsTemplatesPromo
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const loadSmsTemplateInfo = async id => {
      const rq = { InfoId: id }
      const rs = await host.smsTemplate.retrieve(rq)
      const info = rs.Info
      body.value = info.MessageBody
    }

    const sendTextClick = () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }

      context.emit('update', {
        eventType: eventType.value,
        smsTemplateId: smsTemplateId.value,
        body: body.value
      })
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const rules = {
      body: { required }
    }

    const vuelidate = useVuelidate(rules, { body })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    initDialog()

    return {
      selectLists,
      eventType,
      smsTemplateId,
      body,
      applicableTemplateList,
      sendTextClick,
      cancelClick,
      feature,
      substitutionList,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss"></style>
