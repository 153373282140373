<template>
  <div>
    <v-card max-width="9000">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Email Template List
        </DialogTitle>
      </v-card-title>
      <v-data-table
        dense
        :items-per-page="10"
        :footer-props="{
          'show-first-last-page': true
        }"
        :headers="headers"
        :items="infoList"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:item.Actions="{ item }">
          <v-icon small class="mr-2" @click="editItemClick(item)">
            {{ icon.lineItemEdit }}
          </v-icon>
          <v-icon small @click="deleteItemClick(item)">
            {{ icon.lineItemDelete }}
          </v-icon>
        </template>
      </v-data-table>
      <div class="table-footer-prepend d-flex pl-2 align-center">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="newItemClick">
              <v-icon>{{ icon.lineItemAdd }}</v-icon>
            </v-btn>
          </template>
          <span>New Email Template</span>
        </v-tooltip>
      </div>
    </v-card>

    <v-dialog v-model="showEmailTemplateEditorDialog" width="1146" persistent>
      <EmailTemplateEditor
        :emailTemplateId="selectedEmailTemplateId"
        :isDialogOpen="showEmailTemplateEditorDialog"
        @close="emailTemplateEditorCloseEvent"
        @update="emailTemplateEditorUpdateEvent"
      ></EmailTemplateEditor>
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import EmailTemplateEditor from '@/components/Setup/MessagingTemplates/EmailTemplateEditor'

export default {
  name: 'EmailTemplateList',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { EmailTemplateEditor },
  setup(props) {
    const dialogUtil = ref(null)
    const loading = ref(false)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      infoList.value = []
      getEmailTemplateList()
    }

    const infoList = ref([])
    const selectedEmailTemplateId = ref(null)
    const showEmailTemplateEditorDialog = ref(false)

    const headers = [
      { value: 'EmailEvent', text: 'Event', width: 100, align: 'left' },
      {
        value: 'TemplateName',
        text: 'Tempate Name',
        width: 100,
        align: 'left'
      },
      { value: 'Subject', text: 'Subject', width: 100, align: 'left' },
      { value: 'SortOrder', text: 'Order', width: 40, align: 'right' },
      {
        value: 'Actions',
        text: 'Actions',
        sortable: false,
        width: 60,
        align: 'right'
      }
    ]

    function editItemClick(item) {
      selectedEmailTemplateId.value = item.EmailTemplateId
      showEmailTemplateEditorDialog.value = true
    }

    function deleteItemClick(item) {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete Email Template: ${item.TemplateName}`
        })
        .then(() => performDeleteEmailTemplate(item.EmailTemplateId))
        .catch(() => {
          return
        })
    }

    function emailTemplateEditorCloseEvent() {
      showEmailTemplateEditorDialog.value = false
    }

    function emailTemplateEditorUpdateEvent() {
      showEmailTemplateEditorDialog.value = false
      getEmailTemplateList()
    }

    async function getEmailTemplateList() {
      loading.value = true
      const rq = {}
      const rs = await host.emailTemplate.list(rq)
      infoList.value = rs.InfoList
      loading.value = false
    }

    async function performDeleteEmailTemplate(id) {
      const rq = { InfoId: id }
      const rs = await host.emailTemplate.delete(rq)
      if (rs.IsSuccess) {
        getEmailTemplateList()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    function newItemClick() {
      selectedEmailTemplateId.value = null
      showEmailTemplateEditorDialog.value = true
    }

    initDialog()

    return {
      dialogUtil,
      headers,
      infoList,
      selectedEmailTemplateId,
      editItemClick,
      deleteItemClick,
      newItemClick,
      showEmailTemplateEditorDialog,
      emailTemplateEditorCloseEvent,
      emailTemplateEditorUpdateEvent,
      icon,
      loading
    }
  }
}
</script>

<style lang="scss" scoped></style>
