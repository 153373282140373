<template>
  <div>
    <v-menu :nudge-width="1000" translate="slide-x-reverse-transition" offset-x>
      <template #activator="{ on: onMenu }">
        <v-tooltip bottom>
          <template #activator="{ on: onTooltip }">
            <v-btn icon v-on="{ ...onMenu, ...onTooltip }">
              <v-icon>{{ icon.setupMenu }}</v-icon>
            </v-btn>
          </template>
          <span>Settings Menu</span>
        </v-tooltip>
      </template>
      <v-card height="500" width="1050" shaped class="mx-auto indigo lighten-5">
        <v-card-title>Settings</v-card-title>
        <v-card-text>
          <div class="d-flex">
            <div v-for="category in categoryList" :key="category">
              <div>
                <v-card class="mx-2">
                  <v-card-title>{{ category }}</v-card-title>
                  <v-card-text>
                    <v-list dense>
                      <v-list-item
                        v-for="(dialogMenuItem, index) in categoryItems(
                          category
                        )"
                        :key="index"
                        @click="menuItemClick(dialogMenuItem)"
                        dense
                      >
                        <v-list-item-icon>
                          <v-icon :color="dialogMenuItem.color">{{
                            dialogMenuItem.icon
                          }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            dialogMenuItem.title
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>

    <v-dialog
      v-model="menuItemList.autoTaskRules.showDialog"
      :width="menuItemList.autoTaskRules.dialogWidth"
    >
      <AutoTaskRuleList
        :isDialogOpen="menuItemList.autoTaskRules.showDialog"
        @close="menuItemList.autoTaskRules.closeEvent"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.billingGroups.showDialog"
      :width="menuItemList.billingGroups.dialogWidth"
    >
      <BillingGroupList
        :isDialogOpen="menuItemList.billingGroups.showDialog"
        @close="menuItemList.billingGroups.closeEvent"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.commissions.showDialog"
      :width="menuItemList.commissions.dialogWidth"
    >
      <CommissionLists :isDialogOpen="menuItemList.commissions.showDialog" />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.customerAlertDefs.showDialog"
      :width="menuItemList.customerAlertDefs.dialogWidth"
    >
      <AlertTypeList
        :isDialogOpen="menuItemList.customerAlertDefs.showDialog"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.customerTypes.showDialog"
      :width="menuItemList.customerTypes.dialogWidth"
    >
      <CustomerTypeList :isDialogOpen="menuItemList.customerTypes.showDialog" />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.customerCustomInfo.showDialog"
      :width="menuItemList.customerCustomInfo.dialogWidth"
    >
      <CustomerCustomInfoList
        :isDialogOpen="menuItemList.customerCustomInfo.showDialog"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.customViews.showDialog"
      :width="menuItemList.customViews.dialogWidth"
    >
      <CustomViewList :isDialogOpen="menuItemList.customViews.showDialog" />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.messagingTemplates.showDialog"
      :width="menuItemList.messagingTemplates.dialogWidth"
    >
      <MessagingTemplateSetup
        :isDialogOpen="menuItemList.messagingTemplates.showDialog"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.measureTypes.showDialog"
      :width="menuItemList.measureTypes.dialogWidth"
    >
      <MeasureTypeList :isDialogOpen="menuItemList.measureTypes.showDialog" />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.officeInfo.showDialog"
      :width="menuItemList.officeInfo.dialogWidth"
    >
      <OfficeInfoEditor
        :isDialogOpen="menuItemList.officeInfo.showDialog"
        @close="menuItemList.officeInfo.closeEvent"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.onlinePaymentSetup.showDialog"
      :width="menuItemList.onlinePaymentSetup.dialogWidth"
    >
      <OnlinePaymentSetup
        :isDialogOpen="menuItemList.onlinePaymentSetup.showDialog"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.rates.showDialog"
      :width="menuItemList.rates.dialogWidth"
    >
      <RateList
        :isDialogOpen="menuItemList.rates.showDialog"
        @close="menuItemList.rates.closeEvent"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.referralTypes.showDialog"
      :width="menuItemList.referralTypes.dialogWidth"
    >
      <ReferralTypeList :isDialogOpen="menuItemList.referralTypes.showDialog" />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.salesTaxes.showDialog"
      :width="menuItemList.salesTaxes.dialogWidth"
    >
      <SalesTaxList
        :isDialogOpen="menuItemList.salesTaxes.showDialog"
        @close="menuItemList.salesTaxes.closeEvent"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.serviceableTypes.showDialog"
      :width="menuItemList.serviceableTypes.dialogWidth"
    >
      <ServiceableTypeList
        :isDialogOpen="menuItemList.serviceableTypes.showDialog"
        @close="menuItemList.serviceableTypes.closeEvent"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.serviceSeasons.showDialog"
      :width="menuItemList.serviceSeasons.dialogWidth"
    >
      <ScheduledServiceSeasonList
        :isDialogOpen="menuItemList.serviceSeasons.showDialog"
        @close="menuItemList.serviceSeasons.closeEvent"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.serviceRoutes.showDialog"
      :width="menuItemList.serviceRoutes.dialogWidth"
    >
      <ServiceRouteList
        :isDialogOpen="menuItemList.serviceRoutes.showDialog"
        @close="menuItemList.serviceRoutes.closeEvent"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.serviceTypes.showDialog"
      :width="menuItemList.serviceTypes.dialogWidth"
    >
      <ServiceTypeList
        :isDialogOpen="menuItemList.serviceTypes.showDialog"
        @close="menuItemList.serviceTypes.closeEvent"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.siteCustomInfo.showDialog"
      :width="menuItemList.siteCustomInfo.dialogWidth"
    >
      <SiteCustomInfoList
        :isDialogOpen="menuItemList.siteCustomInfo.showDialog"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.taskTypes.showDialog"
      :width="menuItemList.taskTypes.dialogWidth"
    >
      <TaskTypeList
        :isDialogOpen="menuItemList.taskTypes.showDialog"
        @close="menuItemList.taskTypes.closeEvent"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.terminationTypes.showDialog"
      :width="menuItemList.terminationTypes.dialogWidth"
    >
      <TerminationTypeList
        :isDialogOpen="menuItemList.terminationTypes.showDialog"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.tranCodeGroups.showDialog"
      :width="menuItemList.tranCodeGroups.dialogWidth"
    >
      <TranCodeGroupList
        :isDialogOpen="menuItemList.tranCodeGroups.showDialog"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.tranCodes.showDialog"
      :width="menuItemList.tranCodes.dialogWidth"
    >
      <TranCodeList :isDialogOpen="menuItemList.tranCodes.showDialog" />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.treatmentTypes.showDialog"
      :width="menuItemList.treatmentTypes.dialogWidth"
    >
      <TreatmentTypeList
        :isDialogOpen="menuItemList.treatmentTypes.showDialog"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.users.showDialog"
      :width="menuItemList.users.dialogWidth"
    >
      <UserList :isDialogOpen="menuItemList.users.showDialog" />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.userGroups.showDialog"
      :width="menuItemList.userGroups.dialogWidth"
    >
      <UserGroupList :isDialogOpen="menuItemList.userGroups.showDialog" />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.reportDesigner.showDialog"
      :width="menuItemList.reportDesigner.dialogWidth"
    >
      <ReportDesignerLauncher
        :isDialogOpen="menuItemList.reportDesigner.showDialog"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.officeSettings.showDialog"
      :width="menuItemList.officeSettings.dialogWidth"
    >
      <OfficeSettingsEditor
        :isDialogOpen="menuItemList.officeSettings.showDialog"
        @close="menuItemList.officeSettings.closeEvent"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.customerPortalSettings.showDialog"
      :width="menuItemList.customerPortalSettings.dialogWidth"
    >
      <PortalSettingsEditor
        :isDialogOpen="menuItemList.customerPortalSettings.showDialog"
        @close="menuItemList.customerPortalSettings.closeEvent"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.syncWithList.showDialog"
      :width="menuItemList.syncWithList.dialogWidth"
    >
      <SyncWithList
        :isDialogOpen="menuItemList.syncWithList.showDialog"
        @close="menuItemList.syncWithList.closeEvent"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { icon } from '@/use/Constants'
import AlertTypeList from '@/components/Setup/Customer/AlertTypeList'
import AutoTaskRuleList from '@/components/Setup/Office/AutoTaskRuleList'
import BillingGroupList from '@/components/Setup/Billing/BillingGroupList'
import CommissionLists from '@/components/Setup/Billing/CommissionLists'
import CustomerTypeList from '@/components/Setup/Customer/CustomerTypeList'
import CustomerCustomInfoList from '@/components/Setup/Customer/CustomerCustomInfoList'
import CustomViewList from '@/components/Setup/Customer/CustomView/CustomViewList'
import MessagingTemplateSetup from '@/components/Setup/MessagingTemplates/MessagingTemplateSetup'
import OfficeInfoEditor from '@/components/Setup/Office/OfficeInfoEditor'
import MeasureTypeList from '@/components/Setup/Service/MeasureTypeList'
import OfficeSettingsEditor from '@/components/Setup/Office/OfficeSettingsEditor'
import OnlinePaymentSetup from '@/components/Setup/OnlinePayments/OnlinePaymentSetup'
import PortalSettingsEditor from '@/components/Setup/Customer/PortalSettingsEditor'
import RateList from '@/components/Setup/Billing/RateList'
import ReferralTypeList from '@/components/Setup/Customer/ReferralTypeList'
import ReportDesignerLauncher from '@/components/Setup/Office/ReportDesignerLauncher'
import SalesTaxList from '@/components/Setup/Billing/SalesTaxList'
import ScheduledServiceSeasonList from '@/components/Setup/Service/ScheduledServiceSeasonList'
import ServiceableTypeList from '@/components/Setup/Service/ServiceableTypeList'
import ServiceRouteList from '@/components/Setup/Service/ServiceRouteList'
import ServiceTypeList from '@/components/Setup/Service/ServiceTypeList'
import SetupMenuItemList from '@/components/layout/SetupMenuItemList'
import SiteCustomInfoList from '@/components/Setup/Site/SiteCustomInfoList'
import SyncWithList from '@/components/Setup/Billing/SyncWithList'
import TaskTypeList from '@/components/Setup/Office/TaskTypeList'
import TerminationTypeList from '@/components/Setup/Customer/TerminationTypeList'
import TranCodeGroupList from '@/components/Setup/Billing/TranCodeGroupList'
import TranCodeList from '@/components/Setup/Billing/TranCodeList'
import TreatmentTypeList from '@/components/Setup/Service/TreatmentTypeList'
import UserGroupList from '@/components/Setup/User/UserGroupList'
import UserList from '@/components/Setup/User/UserList'
export default {
  name: 'SetupButton',
  components: {
    AlertTypeList,
    AutoTaskRuleList,
    BillingGroupList,
    CommissionLists,
    CustomerTypeList,
    CustomerCustomInfoList,
    CustomViewList,
    MessagingTemplateSetup,
    OfficeInfoEditor,
    MeasureTypeList,
    OfficeSettingsEditor,
    OnlinePaymentSetup,
    PortalSettingsEditor,
    SyncWithList,
    RateList,
    ReferralTypeList,
    ReportDesignerLauncher,
    SalesTaxList,
    ScheduledServiceSeasonList,
    ServiceableTypeList,
    ServiceRouteList,
    ServiceTypeList,
    SiteCustomInfoList,
    TaskTypeList,
    TerminationTypeList,
    TranCodeGroupList,
    TranCodeList,
    TreatmentTypeList,
    UserGroupList,
    UserList
  },
  setup() {
    const menuItemList = ref(SetupMenuItemList)

    const menuItemClick = menuItem => {
      menuItem.showDialog = true
    }

    const categoryList = computed(() => {
      return new Set(
        Object.keys(menuItemList.value).map(x => menuItemList.value[x].category)
      )
    })

    const categoryItems = category => {
      return Object.values(menuItemList.value).filter(
        x => x.category == category
      )
    }

    return {
      menuItemClick,
      menuItemList,
      categoryList,
      categoryItems,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
