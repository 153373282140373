<template>
  <div>
    <v-card class="indigo lighten-5" height="100%">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Messaging Template Setup
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="tabPage">
          <v-tab key="A">Email Template List</v-tab>
          <v-tab key="B">Sms Template List</v-tab>

          <v-tab-item key="A" class="indigo lighten-5">
            <div class="mt-3">
              <EmailTemplateList :isDialogOpen="tabPage == 0" />
            </div>
          </v-tab-item>
          <v-tab-item key="B" class="indigo lighten-5">
            <div class="mt-3">
              <SmsTemplateList :isDialogOpen="tabPage == 1" />
            </div>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import EmailTemplateList from '@/components/Setup/MessagingTemplates/EmailTemplateList.vue'
import SmsTemplateList from '@/components/Setup/MessagingTemplates/SmsTemplateList.vue'
export default {
  name: 'MessagingTemplateSetup',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    EmailTemplateList,
    SmsTemplateList
  },
  setup(props) {
    const tabPage = ref(0)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      tabPage.value = 0
    }

    return { tabPage }
  }
}
</script>

<style lang="scss" scoped></style>
