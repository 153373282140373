import { render, staticRenderFns } from "./MessagingTemplateSetup.vue?vue&type=template&id=340d193c&scoped=true"
import script from "./MessagingTemplateSetup.vue?vue&type=script&lang=js"
export * from "./MessagingTemplateSetup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "340d193c",
  null
  
)

export default component.exports