<template>
  <div class="blue lighten-4 full-height">
    <v-card v-if="formData && selectLists" class="blue lighten-4">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          <div class="d-flex">
            <div class="subtitle-1">
              {{ isEditMode ? 'Edit' : 'New' }} Service For Site:
              {{ formData.SiteName }}
            </div>
            <v-spacer />
            <ActivityLogButton
              v-if="isEditMode"
              :description="
                `Scheduled Service For Site ${
                  formData.SiteName
                } on ${toLocaleDateString(formData.ServiceDate)}`
              "
              tableName="ScheduledService"
              :recordId="formData.ScheduledServiceId"
            />
          </div>
        </DialogTitle>
      </v-card-title>
      <v-card-text class="blue lighten-4">
        <div class="d-flex">
          <div>
            <v-card class="mt-2 ml-2" width="400px">
              <v-card-title>
                <div class="text-subtitle-1 blue--text">
                  Service Info
                </div>
              </v-card-title>
              <v-card-subtitle v-if="routeOrRecurring">
                <div v-if="formData.ServiceRouteCode !== null">
                  <span>Route </span>
                  <span class="text-subtitle-2 purple--text">
                    {{ formData.ServiceRouteCode }}
                  </span>
                </div>
                <div class="d-flex">
                  <div class="mt-2">Recurs</div>
                  <div class="text-subtitle-2 purple--text mt-2 ml-1">
                    {{ formData.RepeatInfo }}
                  </div>
                  <v-spacer />
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="formData.RecurringServiceId"
                        icon
                        @click="recurringServiceClick"
                      >
                        <v-icon
                          :color="feature.services.color"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ feature.recurringServices.icon }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Recurring Service</span>
                  </v-tooltip>
                </div>
              </v-card-subtitle>
              <v-card-text>
                <SitePicker
                  label="Site"
                  v-model="formData.SiteId"
                  v-bind:text.sync="formData.SiteName"
                  :error-messages="errorMessages(vuelidate.formData.SiteId)"
                  activeOnly
                  dense
                  @blur="vuelidate.formData.SiteId.$touch()"
                />
                <ServiceTypePicker
                  v-model="formData.ServiceDefId"
                  :error-messages="
                    errorMessages(vuelidate.formData.ServiceDefId)
                  "
                  @blur="vuelidate.formData.ServiceDefId.$touch()"
                />
                <div class="d-flex">
                  <DatePicker
                    v-model="formData.ServiceDate"
                    label="Service Date"
                    :error-messages="
                      errorMessages(vuelidate.formData.ServiceDate)
                    "
                    dense
                    @blur="vuelidate.formData.ServiceDate.$touch()"
                  />
                  <NearbyServicesButton
                    v-if="formData.SiteId"
                    :siteId="formData.SiteId"
                    :serviceDate="formData.ServiceDate || todayISOString()"
                    :serviceTypeId="formData.ServiceDefId"
                    @update="nearbyServiceSelected"
                  />
                </div>
                <UserPicker
                  v-model="formData.UserId"
                  label="Assigned To"
                  clearable
                  :error-messages="errorMessages(vuelidate.formData.UserId)"
                  @blur="vuelidate.formData.UserId.$touch()"
                />
                <v-text-field
                  v-model="formData.StopNumber"
                  type="number"
                  label="Stop Number"
                  dense
                />
                <div class="d-flex flex-wrap">
                  <v-currency-field
                    v-if="formData.IsAmountOverride"
                    v-model="formData.AmountToCharge"
                    label="Service Amount"
                    clearable
                    dense
                  />
                  <div v-else class="mt-1">
                    Amount: {{ toCurrency(formData.AmountToCharge) }}
                  </div>
                  <v-spacer />
                  <v-checkbox
                    v-model="formData.IsAmountOverride"
                    label="Override"
                  />
                </div>
                <v-textarea
                  v-model="formData.Comments"
                  label="Comments"
                  rows="3"
                  dense
                />
              </v-card-text>
            </v-card>
            <v-card class="mt-2 ml-2" width="400px">
              <v-card-text>
                <v-card-actions>
                  <v-btn color="success" @click="saveClick">
                    <span>Save</span>
                    <v-icon right>{{ icon.save }}</v-icon>
                  </v-btn>
                  <v-btn class="ml-2" color="error" @click="cancelClick">
                    <span>Back</span>
                    <v-icon right>{{ icon.exit }}</v-icon>
                  </v-btn>
                  <v-divider />
                  <v-btn
                    v-if="formData.ScheduledServiceId"
                    color="secondary"
                    @click="deleteClick"
                  >
                    <span>Delete</span>
                    <v-icon right>{{ icon.delete }}</v-icon>
                  </v-btn>
                </v-card-actions>
                <v-card-actions>
                  <v-btn color="primary" @click="printClick">
                    <span>Print</span>
                    <v-icon right>{{ icon.report }}</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="formData.ScheduledServiceId"
                    class="ml-2"
                    color="primary"
                    @click="showInvoice"
                  >
                    <span>Invoice</span>
                    <v-icon right>{{ icon.invoice }}</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </div>

          <div class="ml-4 mt-2">
            <v-tabs vertical v-model="selectedTab" background-color="#BBDEFB">
              <v-tab key="1">Service Status</v-tab>
              <v-tab key="2" :disabled="!checklistEligible">Checklist</v-tab>
              <v-tab key="3" :disabled="formData.Status === 'O'">
                Notification
              </v-tab>
              <v-tab key="4" :disabled="formData.ScheduledServiceId === null">
                Measures
              </v-tab>
              <v-tab key="5" :disabled="formData.ScheduledServiceId === null">
                Treatments
              </v-tab>
              <v-tab key="6" :disabled="formData.ScheduledServiceId === null">
                PhotoList
              </v-tab>

              <v-tab-item key="1" class="blue lighten-4 full-height">
                <v-card width="400px">
                  <v-card-title>
                    <div class="text-subtitle-1 blue--text">
                      Service Status
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-select
                      v-model="formData.Status"
                      :items="selectLists.ServiceStatus"
                      item-text="Text"
                      item-value="Value"
                      label="Status"
                      @change="statusChange"
                      dense
                    />
                    <TimePicker
                      v-model="formData.EarliestArrivalTime"
                      label="Earliest Arrival Time"
                      clearable
                      dense
                    />
                    <TimePicker
                      v-model="formData.LatestArrivalTime"
                      label="Latest Arrival Time"
                      clearable
                      dense
                    />
                    <DurationPicker
                      v-model="formData.EstimatedDurationMinutes"
                      label="Estimated Duration"
                      clearable
                      dense
                    />
                    <DateTimePicker
                      v-model="formData.WhenStarted"
                      label="When Started"
                      dense
                    />
                    <DateTimePicker
                      v-model="formData.WhenCompleted"
                      label="When Completed"
                      dense
                    />
                    <v-textarea
                      v-if="formData.Status === 'C'"
                      v-model="formData.CompletionNotes"
                      label="Completion Notes"
                      dense
                    />
                    <v-textarea
                      v-if="formData.RecurringServiceComments"
                      readonly
                      v-model="formData.RecurringServiceComments"
                      label="Recurring Service Comments"
                      dense
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item key="2" class="blue lighten-4 full-height">
                <v-card width="400px" min-height="340px">
                  <v-card-title>
                    <div class="text-subtitle-1 blue--text">
                      Checklist
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table
                      v-if="checklistFormData"
                      dense
                      fixed-header
                      height="600"
                    >
                      <template v-slot:default>
                        <thead>
                          <th class="text-left">
                            <span class="ml-5">Item</span>
                          </th>
                          <th class="text-left">
                            <span class="ml-4">Previous</span>
                          </th>
                        </thead>
                        <tbody>
                          <tr
                            v-for="checklistItem in checklistFormData.Checklist"
                            :key="checklistItem.ServiceDefChecklistItemId"
                          >
                            <td>
                              <v-checkbox
                                v-model="checklistItem.IsCompleted"
                                :label="checklistItem.Description"
                                dense
                              />
                            </td>
                            <td>
                              <div v-if="checklistItem.WhenLastCompleted">
                                {{
                                  toLocaleDateString(
                                    checklistItem.WhenLastCompleted
                                  )
                                }}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item key="3" class="blue lighten-4 full-height">
                <div class="d-flex align-content-space-around flex-wrap">
                  <v-card width="400px" class="mr-2 mb-2">
                    <v-card-title>
                      <div class="text-subtitle-1 blue--text">
                        Notification
                      </div>
                    </v-card-title>
                    <v-card-text>
                      <v-checkbox
                        v-model="formData.SendEmail"
                        label="Send Email"
                        class="mb-2"
                        dense
                      />
                      <v-select
                        v-model="formData.EmailTemplateId"
                        :items="selectLists.EmailTemplates"
                        item-text="Text"
                        item-value="Value"
                        label="Email Template"
                        clearable
                        dense
                      />
                      <div v-if="emailTemplateInfo !== null">
                        <v-checkbox
                          v-for="insert in emailTemplateInfo.Inserts"
                          :key="insert.Name"
                          v-model="insert.IsSelected"
                          :label="insert.Name"
                          dense
                        />
                      </div>
                    </v-card-text>
                  </v-card>
                  <v-card
                    v-if="emailTemplateInfo.Body"
                    width="400px"
                    min-height="500px"
                  >
                    <v-card-title>
                      <div class="text-subtitle-1 blue--text">
                        Email Body
                      </div>
                    </v-card-title>
                    <v-card-text>
                      <ckeditor
                        :editor="editor"
                        v-model="formData.Body"
                        :config="{}"
                        height="400"
                      />
                    </v-card-text>
                  </v-card>
                </div>
              </v-tab-item>

              <v-tab-item key="4" class="blue lighten-4 full-height">
                <div v-if="measureTabSelected">
                  <MeasureList
                    :isDialogOpen="measureTabSelected"
                    isSummary
                    :width="400"
                    :siteId="formData.SiteId"
                    :siteDescription="formData.SiteName"
                    :scheduledServiceId="formData.ScheduledServiceId"
                  />
                </div>
              </v-tab-item>

              <v-tab-item key="5" class="blue lighten-4 full-height">
                <div v-if="treatmentTabSelected">
                  <TreatmentList
                    :isDialogOpen="treatmentTabSelected"
                    isSummary
                    :width="400"
                    :siteId="formData.SiteId"
                    :siteDescription="formData.SiteName"
                    :scheduledServiceId="formData.ScheduledServiceId"
                  />
                </div>
              </v-tab-item>

              <v-tab-item key="6" class="blue lighten-4 full-height">
                <v-card width="400px">
                  <v-card-title>
                    <div class="text-subtitle-1 blue--text">
                      Photos
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <PhotoList
                      :siteId="formData.SiteId"
                      :scheduledServiceId="formData.ScheduledServiceId"
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog
      v-if="formData"
      v-model="showInvoiceDialog"
      width="1000"
      persistent
    >
      <Invoice
        :isDialogOpen="showInvoiceDialog"
        :customerId="formData.CustomerId"
        :invoiceId="formData.InvoiceId"
        :scheduledServiceId="formData.ScheduledServiceId"
        @close="invoiceDialogClose"
        @update="invoiceDialogUpdate"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { icon, feature } from '@/use/Constants'
import { selectListCache } from '@/services/SelectListCache'
import { host } from '@/services/HostAPI'
import ActivityLogButton from '@/components/Common/ActivityLogButton'
import NearbyServicesButton from '@/components/ScheduledService/NearbyServicesButton'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Invoice from '@/components/Billing/Invoice'
import MeasureList from '@/components/Site/MeasureList'
import PhotoList from '@/components/Common/PhotoList'
import reportInfo from '@/constants/reports'
import ReportService from '@/services/ReportService'
import SitePicker from '@/components/Site/SitePicker'
import TreatmentList from '@/components/Site/TreatmentList'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { todayISOString, toLocaleDateString } from '@/use/DateFormatter'
import { toCurrency } from '@/use/NumberFormatter'
import { isoTimeToShortTime } from '@/use/TimeFormatter'

export default {
  name: 'ScheduledServiceEditor',
  components: {
    ActivityLogButton,
    Invoice,
    MeasureList,
    NearbyServicesButton,
    PhotoList,
    SitePicker,
    TreatmentList
  },
  props: {
    siteId: {
      type: String,
      default: null
    },
    scheduledServiceId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    const isEditMode = ref(false)
    const selectedTab = ref(0)
    const formData = ref()
    const selectLists = ref()
    const checklistFormData = ref(null)
    const measureTabSelected = ref(false)
    const treatmentTabSelected = ref(false)

    const emptyEmailTemplateInfo = {
      EmailTemplateId: null,
      Bcc: null,
      Subject: null,
      Body: '',
      Inserts: []
    }

    const emailTemplateInfo = ref({ ...emptyEmailTemplateInfo })
    const showInvoiceDialog = ref(false)
    const editor = ClassicEditor

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      measureTabSelected.value = false
      treatmentTabSelected.value = false
      await loadSelectLists()
      isEditMode.value = props.scheduledServiceId != null
      formData.value = isEditMode.value
        ? await loadScheduledService()
        : await initNewScheduledService()
      emailTemplateInfo.value = { ...emptyEmailTemplateInfo }
      checklistFormData.value = null
      createFormDataWatches()
      selectedTab.value = 0
      vuelidate.value.$reset()
    }

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.Users,
        selectListCache.name.ServiceStatus,
        selectListCache.name.ServiceTypes,
        selectListCache.name.EmailTemplates,
        selectListCache.name.EmailInserts
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const loadScheduledService = async () => {
      const rq = { InfoId: props.scheduledServiceId }
      const rs = await host.scheduledService.retrieve(rq)
      return rs.Info
    }

    const initNewScheduledService = async () => {
      const rq = { Defaults: props.siteId }
      const rs = await host.scheduledService.newTemplate(rq)
      return rs.Info
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const saveClick = async () => {
      vuelidate.value.$touch()
      if (!vuelidate.value.$invalid) {
        if (await saveService()) {
          context.emit('update')
        }
      }
    }

    const saveService = async () => {
      const rq = {
        Info: formData.value,
        Checklist: checklistFormData.value
          ? checklistFormData.value.Checklist
          : null,
        EmailTemplateInfo: emailTemplateInfo.value
      }

      const rs = isEditMode.value
        ? await host.scheduledService.extendedUpdate(rq)
        : await host.scheduledService.extendedCreate(rq)

      if (!rs.IsSuccess) {
        dialogUtil.value.error(rs.Message)
      } else {
        if (!isEditMode.value) {
          formData.value.ScheduledServiceId = rs.InfoId // eslint-disable-line require-atomic-updates
        }
      }

      return rs.IsSuccess
    }

    const printClick = async () => {
      vuelidate.value.$touch()
      if (!vuelidate.value.$invalid) {
        if (await saveService()) {
          printWorkOrder()
        }
      }
    }

    const printWorkOrder = () => {
      const workOrderReportName = selectListCache.attribute(
        selectListCache.name.ServiceTypes,
        formData.value.ServiceDefId,
        'WorkOrderReportName'
      )

      const selectedReportId =
        workOrderReportName === 'Open/Close'
          ? reportInfo.report.workorderOpenClose.reportId
          : workOrderReportName === 'Startup'
          ? reportInfo.report.workorderStartup.reportId
          : workOrderReportName === 'Repair'
          ? reportInfo.report.workorderRepair.reportId
          : reportInfo.report.workorderService.reportId

      ReportService.emitShowReportDialog({
        ReportId: selectedReportId,

        ParameterList: [
          {
            Name: 'ScheduledServiceId',
            Value: formData.value.ScheduledServiceId
          },
          {
            Name: 'ServiceDate',
            Value: formData.value.ServiceDate
          }
        ],
        ParameterHideList: [
          'ScheduledServiceId',
          'ServiceDate',
          'ReportLayout'
        ],
        EmailInfo: {
          SendEmail: false,
          CustomerId: formData.value.CustomerId,
          CustomerName: formData.value.CustomerName,
          Recipient: formData.value.Email
        }
      })
    }

    watch(
      () => selectedTab.value,
      newVal => {
        if (newVal === 1) {
          loadChecklistIfChanged()
        } else if (newVal === 3) {
          measureTabSelected.value = true
        } else if (newVal === 4) {
          treatmentTabSelected.value = true
        }
      }
    )

    const createFormDataWatches = () => {
      watch(
        () => formData.value.EmailTemplateId,
        newVal => {
          loadEmailTemplateInfo(newVal)
        }
      )

      watch(
        () => formData.value.SiteId,
        () => {
          loadChecklistIfChanged()
        }
      )

      watch(
        () => formData.value.ServiceDefId,
        newVal => {
          loadChecklistIfChanged()
          formData.value.EmailTemplateId = selectListCache.attribute(
            selectListCache.name.ServiceTypes,
            newVal,
            'EmailTemplateId'
          )
          if (!formData.value.IsAmountOverride) {
            formData.value.AmountToCharge = selectListCache.attribute(
              selectListCache.name.ServiceTypes,
              newVal,
              'DefaultAmountToCharge'
            )
          }
        }
      )

      watch(
        () => formData.value.ServiceDate,
        () => {
          loadChecklistIfChanged()
        }
      )
    }

    const createChecklistFormDataWatch = () => {
      watch(
        () => checklistFormData.value.CheckList,
        () => {
          applySubstitutions()
        },
        { deep: true }
      )
    }

    watch(
      () => emailTemplateInfo.value.Inserts,
      () => {
        applySubstitutions()
      },
      { deep: true }
    )

    const loadChecklistIfChanged = () => {
      if (checklistEligible.value) {
        if (
          checklistFormData.value === null ||
          checklistFormData.value.SiteId !== formData.value.SiteId ||
          checklistFormData.value.ServiceDefId !==
            formData.value.ServiceDefId ||
          checklistFormData.value.ServiceDate !== formData.value.ServiceDate
        ) {
          getChecklist()
        }
      }
    }

    const checklistEligible = computed(() => {
      return (
        formData.value.SiteId !== null &&
        formData.value.ServiceDefId !== null &&
        formData.value.ServiceDate !== null
      )
    })

    const loadEmailTemplateInfo = async id => {
      const rq = { InfoId: id }
      const rs = await host.emailTemplate.retrieve(rq)
      emailTemplateInfo.value = rs.Info
      formData.value.Bcc = rs.Info.Bcc
      formData.value.Subject = rs.Info.Subject
      formData.value.Body = rs.Info.Body
      applySubstitutions()
    }

    const getChecklist = async () => {
      const rq = {
        SiteId: formData.value.SiteId,
        ServiceDefId: formData.value.ServiceDefId,
        ServiceDate: formData.value.ServiceDate,
        ScheduledServiceId: formData.value.ScheduledServiceId
      }
      const rs = await host.scheduledService.getChecklist(rq)
      checklistFormData.value = rs
      createChecklistFormDataWatch()
    }

    const applySubstitutions = () => {
      if (!emailTemplateInfo.value || !formData.value.Body) {
        return
      }

      const advisories =
        '<ul>' +
        emailTemplateInfo.value.Inserts.filter(element => element.IsSelected)
          .map(element => '<li>' + element.Text + '</li>')
          .join('') +
        '</ul>'
      const checklistItems =
        checklistFormData.value && checklistFormData.value.Checklist
          ? '<ul>' +
            checklistFormData.value.Checklist.filter(
              element => element.IsCompleted
            )
              .map(element => '<li>' + element.Description + '</li>')
              .join('') +
            '</ul>'
          : ''

      formData.value.Body = emailTemplateInfo.value.Body.replace(
        '{0}',
        advisories
      )
        .replace('{1}', formData.value.CustomerName)
        .replace('{2}', formData.value.SiteName)
        .replace('{3}', formData.value.CompletionNotes)
        .replace('{4}', checklistItems)
        .replace(
          '{7}',
          selectListCache.attribute(
            selectListCache.name.Users,
            formData.value.UserId,
            'Text'
          )
        )
        .replace(
          '{8}',
          selectListCache.attribute(
            selectListCache.name.ServiceTypes,
            formData.value.ServiceDefId,
            'Text'
          )
        )
        .replace('{9}', formData.value.RepeatInfo)
        .replace('{11}', formData.value.Comments ?? '')
        .replace(
          '{12}',
          isoTimeToShortTime(formData.value.WhenStarted) ?? '(n/a)'
        )
        .replace(
          '{13}',
          isoTimeToShortTime(formData.value.WhenCompleted) ?? '(n/a)'
        )
    }

    const showInvoice = () => {
      showInvoiceDialog.value = true
    }

    const invoiceDialogUpdate = event => {
      formData.value.InvoiceId = event
      showInvoiceDialog.value = false
    }

    const invoiceDialogClose = () => {
      showInvoiceDialog.value = false
    }

    const routeOrRecurring = computed(() => {
      return (
        formData.value.ServiceRouteId !== null ||
        formData.value.RecurringServiceId !== null
      )
    })

    const statusChange = () => {
      if (formData.value.Status === 'C') {
        if (
          formData.value.EmailTemplateId != null &&
          emailTemplateInfo.value.EmailTemplateId == null
        ) {
          loadEmailTemplateInfo(formData.value.EmailTemplateId)
        }

        if (!formData.value.WhenCompleted) {
          formData.value.WhenCompleted = dateToISOLikeButLocal(new Date())
        }
      }
    }

    const dateToISOLikeButLocal = date => {
      const offsetMs = date.getTimezoneOffset() * 60 * 1000
      const msLocal = date.getTime() - offsetMs
      const dateLocal = new Date(msLocal)
      const iso = dateLocal.toISOString()
      const isoLocal = iso.slice(0, 19)
      return isoLocal
    }

    const deleteClick = () => {
      dialogUtil.value
        .confirm({
          title: 'Please Confirm',
          text: 'Delete this service. Are you sure?'
        })
        .then(() => deleteScheduledService())
        .catch(() => {
          return
        })
    }

    const deleteScheduledService = async () => {
      const rq = { InfoId: props.scheduledServiceId }
      const rs = await host.scheduledService.delete(rq)
      if (rs.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const nearbyServiceSelected = args => {
      formData.value.ServiceDate = args.ServiceDate
      formData.value.UserId = args.UserId
      formData.value.StopNumber = args.StopNumber + 1
      formData.value.ServiceDefId =
        formData.value.ServiceDefId || args.ServiceDefId
    }

    const rules = {
      formData: {
        SiteId: { required },
        ServiceDefId: { required },
        ServiceDate: { required },
        UserId: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const recurringServiceClick = () => {
      context.emit('editRecurringService', formData.value.RecurringServiceId)
      context.emit('close')
    }

    initDialog()

    return {
      dialogUtil,
      isEditMode,
      selectedTab,
      editor,
      formData,
      selectLists,
      saveClick,
      cancelClick,
      deleteClick,
      checklistFormData,
      emailTemplateInfo,
      measureTabSelected,
      treatmentTabSelected,
      printClick,
      routeOrRecurring,
      showInvoiceDialog,
      invoiceDialogClose,
      invoiceDialogUpdate,
      showInvoice,
      statusChange,
      checklistEligible,
      recurringServiceClick,
      nearbyServiceSelected,
      todayISOString,
      toLocaleDateString,
      toCurrency,
      icon,
      feature,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss">
.ck-editor__editable {
  min-height: 500px;
}
</style>
